import { Link } from "gatsby"
import React from "react"
import DogFeeds from "../components/dog-feeds"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AdoptionFee from "../components/shared/adoption-fee"

import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/coming-soon-dogs-banner-bg.jpg"
const ComingSoonDogsPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Coming Soon Dogs" />
    <InnerBanner theme="white" image={banner} title="Coming Soon Dogs" subtitle="Dogs" />
   <AdoptionFee note="If you are interested in meeting one of our Labs, the next step is to complete an adoption application.">
                <p>Below is a list of Labs that will be available for adoption soon. Since we don’t have a shelter, our Labs are housed in foster homes. Labs new to our program are kept in a coming soon status for a minimum of 12 days while our foster families get to know them and to get them ready to be adopted. Coming Soon Labs are not available to meet or to go to public events. When they are cleared to be adopted they will appear on our <strong><Link to="/available-dogs/">Available Labs</Link></strong> page.</p>
                    <p>Click each picture to find what our foster families have to say about each of our dogs - this information is preliminary and may change once the Lab is available to be adopted.</p>
         
    </AdoptionFee>
      <DogFeeds type="coming-soon" per_page={100}/>

  </Layout>
}

export default ComingSoonDogsPage